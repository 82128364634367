import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

export function MentionsPageTemplate({ title, html }) {
	return (
		<div className="medium-contain">
			<h1>{title}</h1>
			<div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
			<style jsx>{`
				h1 {
					text-align: center;
				}
			`}</style>
		</div>
	);
}

export default function MentionsPage({ data }) {
	const { frontmatter, html } = data.markdownRemark;

	return (
		<Layout title={frontmatter.seo.seoTitle} description={frontmatter.seo.seoDescription}>
			<MentionsPageTemplate title={frontmatter.title} html={html} />
		</Layout>
	);
}

export const pageQuery = graphql`
	query MentionsPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "mentions-page" } }) {
			html
			frontmatter {
				title
				seo {
					seoDescription
					seoTitle
				}
			}
		}
	}
`;
